// add your site specific javascript here
(function ( $ ) {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here

	$( function () {
		//First modal registration
		$( '#registrationModalThanks' ).modal( 'show' );

		// If we have an "active" tab we start with that one selected, otherwise we select the first one
		$( '.nav-tabs' ).each( function () {
			var $activeTab = $( this ).find( '.active [data-toggle="tab"]:first' );

			if ( $activeTab.length ) {
				$activeTab.tab();
			} else {
				$( this ).find( '[data-toggle="tab"]:first' ).tab();
			}
		} );

		// Carousel Settings - Home
		$( '.carousel' ).carousel( {} );

        // SWIPE CAROUSEL
        var $carousel = $( '.carousel' );
        if ( $carousel.length ) {
            $carousel.swiperight( function () {
                $( this ).carousel( 'prev' );
            } );
            $carousel.swipeleft( function () {
                $( this ).carousel( 'next' );
            } );
        }
	} );

	$( function () {
		$( this ).find( '[data-zg-role="search-input"]').each( function(){
				//Bind the search function
				$( this ).on('input paste', function(){


					// Retrieve the input field text and reset the count to zero
					var filter = $(this).val(), count = 0;

					// Loop through the comment list
					$('[data-zg-role="search-items"]').each(function(){

						// If the list item does not contain the text phrase fade it out
						if ($(this).text().search(new RegExp(filter, "i")) < 0) {
							$(this).fadeOut();

							// Show the list item if the phrase matches and increase the count by 1
						} else {
							$(this).show();
							//if is the first visible I also show the function definition
							/*if(count == 0){
								console.log($(this).find('a'));
							}*/

							count++;
						}
					});

					// Update the count
					if(count > 0){
						$("#noResult").addClass('hide');
					}
					else{
						$("#noResult").removeClass('hide');
					}
					$("#filter-count").text(count);
					$(".count-hide").removeClass('hide');

				});
			}
		);

	});

	//Scroll slowly to the target
	$( function () {
		$('[data-zg-scrollTo="slowly"]').click(function () {
			var target = $(this).data('zg-target'),
				href = $(this).attr("href"),
				offsetInserted = $(this).data('zg-offset'),
				offset = $(target).offset().top;

			if( offsetInserted ){
				offset -= offsetInserted;
			}
			$('html, body').animate({scrollTop: offset}, 1000);
			window.location.hash = href;
		});
	});


	// Redirecting to the "Messages" tab in the My Acoount page when the user clicks on "View my messages"
	if ( SGL_JS_MANAGER === 'account' && window.location.hash === '#tickets' ) {
		$( '#tabs-account li a[href="#tickets"]' ).tab( 'show' );
	}
	
	if ( openTicketTab ) {
		$( '#tabs-account li a[href="#tickets"]' ).tab( 'show' );
	}
	
	
	
	// Fading out the bouncing arrow for the Home Page slider
	$( window ).scroll( function(){
		$(".arrow").css("opacity", 1 - $(window).scrollTop() / 250);
		//250 is fade pixels
	});

	$( '[data-zg-role="btn-search"]' ).click( function(){
		$( '#searchContainer').toggle( '200' );
	});


	// ===== Scroll to Top ====
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click(function() {
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});
	
	/* SMOOTH SCROLL DOWN */
  $('.smooth-scroll').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top + 75
        }, 1000);
        return false;
    	}
  	}
	});
	
	// NAVBAR TOGGLE MOBILE
	$('#navbar-toggle-mobile').click(function(){
		$('body').toggleClass('navbar-dropdown-mobile-open');
		$(this).toggleClass('navbar-toggle-mobile-active');
	});

	/* UTILITY SLIDE IN MENU - OPEN/CLOSE */
	$('.js-trigger-utility-slide-in').click(function() {
		$('body').toggleClass('utility-slide-in-open');
	});
	
	$('.js-trigger-user-menu').click(function() {
		$('#user-menu').toggleClass('open');
	});
	
	$('.js-trigger-wishlist').click(function() {
		$('#wishlist-menu').toggleClass('open');
	});

	$('.js-trigger-menu-search').click(function() {
		$('#search-menu').toggleClass('open');
	});

	$('.js-trigger-small-cart').click(function() {
		$('#small-cart').toggleClass('open');
	});
	
	
	function closeUtilityMenu() {
			$('body').removeClass('utility-slide-in-open').removeClass('search-slide-in-open').removeClass('menu-slide-in-open');
			$('.js-utility-slide-in').removeClass('open');
	}

	$('.js-utility-slide-in-close').click(function(){
       closeUtilityMenu();
  });

	
	/* CATEGORIES MENU MOBILE - OPEN/CLOSE */
	$('#toggle-cat-menu-mobile').click(function() {
		$('#menu-accordion-wrapper').toggleClass('open');
		$('#menu-accordion').slideToggle('slow');
	});
	
	
	if (homePage == true) {

		// HEADER CHANGE COLOR ON SCROLL & ON MOUSEOVER IN HOMEPAGE
		$('#site_header.header--big .main-nav').mouseover(function() {
			if ( $(window).scrollTop() < 30 ) {	
				$('#site_header').removeClass('header--transparent').removeClass('header--big');
			}
		});

		$('#site_header').mouseleave(function() {
			if ( $(window).scrollTop() < 30 ) {
				if ( $('body').hasClass('mega-menu-active') ) {
					setTimeout(function() { 
						$('#site_header').addClass('header--transparent').addClass('header--big');
					}, 800);
				} else {
					$('#site_header').addClass('header--transparent').addClass('header--big');
				}
			}
		});
		
		if( $(this).scrollTop() >= 30 ) {
			$('#site_header').removeClass('header--transparent').removeClass('header--big');
		} else {
			$('#site_header').addClass('header--transparent').addClass('header--big');
		}
		
		$(window).scroll(function() {
			if( $(this).scrollTop() >= 30 ) {
				$('#site_header').removeClass('header--transparent').removeClass('header--big');
			} else {
				$('#site_header').addClass('header--transparent').addClass('header--big');
			}
		});

	} else if (headerTransparent == true) {
				
		if( $(this).scrollTop() >= 30 ) {
			$('#site_header').removeClass('header--transparent');
		} else {
			$('#site_header').addClass('header--transparent');
			$('#site_header.header--transparent .main-nav').mouseover(function() {
					$('#site_header').removeClass('header--transparent');
			});
		}
		
		$(window).scroll(function() {
			if( $(this).scrollTop() >= 30 ) {
				$('#site_header').removeClass('header--transparent');
			} else {
				$('#site_header').addClass('header--transparent');
			}
		});		
	
	}
	
	if (categoryPage == true || searchPage == true) {
		
		$('#change-grid-2col').click(function() {
			$('.category-list').addClass('two-col');
			$(this).addClass('active');
			$('#change-grid-4col').removeClass('active');
		});

		$('#change-grid-4col').click(function() {
			$('.category-list').removeClass('two-col');
			$(this).addClass('active');
			$('#change-grid-2col').removeClass('active');
		});
		
		/*$(window).load(function() {
 			$('.header-filter a').click(function() {
				if ($(this).parent().parent().hasClass('collapsed')) { 
					$(this).parent().parent().removeClass('collapsed');
				}
				else {
					$('.filter').addClass('collapsed');
					$(this).parent().addClass('collapsed');
				}
			});	
		});*/
		
	}
	
	

	
// GENERIC IMAGE SLIDESHOW (cmsComponent_slider)
$(".owl-carousel-slideshow").owlCarousel({
	items: 1,
	nav: false,
	autoPlay: true,
	dots: true,
	smartSpeed: 800,
	dotsSpeed: 800,
	navSpeed: 800,
	fluidSpeed: 800
	});


	// IMAGE SLIDESHOW IN CORPORATE SECTION
	$(".owl-carousel-collection-photogallery").owlCarousel({
		items: 1,
		autoPlay: false,
		dots: true,
		smartSpeed: 800,
		dotsSpeed: 800,
		navSpeed: 800,
		fluidSpeed: 800,
		responsive:{
					0:{
					},
					600:{
					},
					1000:{
						nav: true,
						navText: ["<i class='fal fa-angle-left'></i>","<i class='fal fa-angle-right'></i>"],        
					}
			}
	});


	// GENERIC SLIDESHOW PRODUCTS (cmsComponent_product)
	$(".owl-carousel-products").owlCarousel({
			nav: true,
			navText: ["<i class='icon-angle-thin-left'></i>","<i class='icon-angle-thin-right'></i>"],
			autoPlay: false,
			dots: false,
			smartSpeed: 800,
			dotsSpeed: 800,
			navSpeed: 800,
			fluidSpeed: 800,
			responsive:{
					0:{
							items:1
					},
					600:{
							items:2
					},
					768:{
							items:3
					},
					1000:{
							items: 4
					}
			}
	});
	
	// GENERIC SLIDESHOW RELATED PRODUCTS
	$(".owl-carousel-related-products").owlCarousel({
			nav: true,
			navText: ["<i class='icon-angle-thin-left'></i>","<i class='icon-angle-thin-right'></i>"],
			autoPlay: false,
			dots: false,
			smartSpeed: 800,
			dotsSpeed: 800,
			navSpeed: 800,
			fluidSpeed: 800,
			responsive:{
					0:{
							items:1
					},
					600:{
							items:2
					},
					768:{
							items:3
					},
					1000:{
							items: 4
					}
			}
	});
	
	// Fancybox	
	$('[data-fancybox="gallery"]').fancybox({
		// Options will go here
		hash: null,
		buttons: [
			"zoom",
			//"share",
			//"slideShow",
			//"fullScreen",
			//"download",
			//"thumbs",
			"close"
		]

	});

	
	
})( jQuery );