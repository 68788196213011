/**
 *
 * Manage gallery (and zoom) of product images in a product page
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg.gallery.rendered Gallery was rendered
 * @type {object} gallery html element div.row.thumbnails
 */

/**
 * @event document#zg.product.optionSelected. Option selected
 */

/* globals handlebarsTemplates */

(function ( $, _ ) {
	'use strict';

	var root = this;

	var Gallery = (function () {

		// CONSTRUCTOR
		// -------------------------------------------------------------------------

		/**
		 *
		 * @param element
		 * @param $parent
		 * @param options
		 * @constructor
		 */
		function Gallery ( element, $parent, options ) {
			this._$element = $( element );
			this._$parent  = $parent;
			this._options  = options;

			this._defaultGallery    = options.gallery;
			this._defaultGallery360 = options.gallery360;
			this._defaultInfo       = options.info;
			this._currentGallery    = null;
			this._currentGallery360 = null;
			this._currentInfo       = null;

			this._$warning = this._$parent.find( this._options.warning );

			this._setEventHandlers();
		}


		// STATIC METHODS
		// -------------------------------------------------------------------------


		/**
		 *
		 * @param options
		 */
		Gallery.setDefaults = function ( options ) {
			if ( options === void 0 ) {
				options = {};
			}

			Gallery.DEFAULTS = _.extend( {}, Gallery.DEFAULTS, options );
		};


		// PUBLIC METHODS
		// -------------------------------------------------------------------------


		/**
		 *
		 * @param options
		 */
		Gallery.prototype.setOptions = function ( options ) {
			if ( options === void 0 ) {
				options = {};
			}

			this._options = _.extend( {}, Gallery.DEFAULTS, this._options, options );
		};


		/**
		 *
		 * @param gallery
		 * @param gallery360
		 * @param info
		 */
		Gallery.prototype.setDefaultGallery = function ( gallery, gallery360, info ) {
			this._defaultGallery    = gallery;
			this._defaultGallery360 = gallery360;
			this._defaultInfo       = info;

			this._renderGallery();
		};


		/**
		 *
		 * @param gallery
		 * @param gallery360
		 * @param info
		 */
		Gallery.prototype.setCurrentGallery = function ( gallery, gallery360, info ) {
			this._currentGallery    = gallery;
			this._currentGallery360 = gallery360;
			this._currentInfo       = info;

			this._renderGallery();
		};


		// PRIVATE METHODS
		// -------------------------------------------------------------------------


		/**
		 *
		 * @private
		 */
		Gallery.prototype._displayWarning = function () {
			if ( this._currentGallery ) {
				this._$warning.hide();
			} else {
				this._$warning.removeClass( 'hidden' ).fadeIn();
			}
		};

		/**
		 *
		 * @method _renderGallery
		 * @fires document#zg.gallery.rendered Gallery was rendered
		 * @private
		 */
		Gallery.prototype._renderGallery = function () {
			var data = {
				info: this._currentInfo || this._defaultInfo || {}
			};
			var template;

			if (this._options.is360 ) {
				data.gallery = this._currentGallery360 || this._defaultGallery360 || null;
				template = this._options.gallery360Template;
			} else {
				data.gallery = this._currentGallery || this._defaultGallery || null;
				template = this._options.galleryTemplate;
			}

			this._displayWarning();

			if ( data.gallery ) {
				if ( this._options.index && _.isNumber( this._options.index ) ) {
					data.gallery = data.gallery[this._options.index] ? [data.gallery[this._options.index]] : null;
				} else if ( this._options.limit && _.isNumber( this._options.limit ) ) {
					data.gallery = data.gallery.slice( 0, this._options.limit );
				}

				this._$element.html( handlebarsTemplates.render( template, data ) );

				$( document ).trigger( 'zg.gallery.rendered', [this._$element, data] );
			}
		};


		// EVENT HANDLERS
		// -------------------------------------------------------------------------


		/**
		 * @method _setEventHandlers
		 * @listen document#zg.product.optionSelected. When user select option, if the option has image, render the gallery for specific option
		 * @private
		 */
		Gallery.prototype._setEventHandlers = function () {
			this._$parent.on( 'zg.product.optionSelected', (function ( e, option ) {
				if ( option.hasImage ) {
					this.setCurrentGallery( option.gallery, option.gallery360, option.info );
				}
				$('#owl-carousel-gallery-product').owlCarousel({
						items: 1,
						loop: false,
						dots: false,
						nav: true,
						navText: ["<i class='icon-angle-thin-left'></i>","<i class='icon-angle-thin-right'></i>"],
						navSpeed: 2000
				});
				
			}).bind( this ) );
		};


		return Gallery;
	}());

	/**
	 * @param {string}  [item]    Selector of gallery elements
	 * @param {string}  [warning] Div contain warning message "Product picture may not reflect selected color"
	 * @param {string}  [galleryTemplate] Handlebars template for thumbnails
	 * @param {int}     [limit]           maximum number of elements to be created in the gallery. 0 or false means no limit
	 */
	Gallery.DEFAULTS = {
		index:              null,
		limit:              null,
		is360:              false,
		warning:            '[data-zg-role="image-overlay"]',
		galleryTemplate:    'gallery-item',
		gallery360Template: 'gallery-item-360'
	};

	/**
	 * @selector data-zg-role="gallery" The plugin start for each selector in the dom when the page load
	 */
	Gallery.SELECTOR = '[data-zg-role="gallery"]';

	root.ZgGallery = Gallery;

	root.zgGalleries = function ( $parent, option, gallery, gallery360, info ) {
		$parent.find( Gallery.SELECTOR ).each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.gallery' );
			var options = $.extend( {}, Gallery.DEFAULTS, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			gallery = gallery || options.gallery || null;

			if ( !data ) {
				$this.data( 'zg.gallery', (data = new Gallery( this, $parent, options )) );
				data.setDefaultGallery( gallery, gallery360, info );
			}
		} );
	};

}).call( this, jQuery, _ );
