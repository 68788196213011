/**
 * zg-send-email.js
 *
 * Send, with ajax call, all inputs of the form.
 * It is also possible to add some data attributes in order to send an email to a specific email address
 * using a specific custom email template which will be populated with content from a CMS content.
 * To see an example you can check the typology "page_form"
 * (http://skeleton.inscadenza.com/admin/cmscontent/action/list/type/2461/typology/page/)
 * and try to find a content there with linked content to the "component_send_email_to_user" content type
 *
 * In the form should be is an input with name="to_email" filled with email encoded base 64.
 * <input id="toemail" type="hidden" name="to_email" value="{'example@email.com'|base64_encode}">
 * And input text or hidden name="subject" with subject of the email
 * <input type="hidden" name="subject" value="Prova form contatti" >
 *
 * You can see example code in static_en_tests.html
 * http://skeleton.inscadenza.com.zgstage.com/en/tests.html (Send email plugin)
 *
 *
 * @author Fabio Polizzi <fpolizzi@kooomo.com>
 *
 */

/**
 * @event document#submit Submit of selector form
 * @type {null}
 */

/**
 * @selector data-zg-role="template_data" Hidden div which will contain the content retrieved from CMS that will be passed
 * to the custom email template. This email will be sent to the user after submitting the original form
 */

/**
 * @event SendEmail#zg-notification Generic notification. Used by 2002-zg-notifier.js to display the notification
 * @type {object}
 * @property {string} eventType - Typology of event. In this plugin is 'sendFormEmail'
 * @property {string} message - The notification message. In this plugin is response by backend
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event. In this plugin is 'sendFormEmail'
 * @property {string} message - The error message.
 */

/* global DEBUG */

(function ( $, _ ) {
	'use strict';

	var root = this;
	/**
	 * @selector data-zg-role="zg_send_email" The plugin start when form with selector (or form inner element with selector) submit
	 */
	var SELECTOR = '[data-zg-role="zg_send_email"]';

	var DEFAULTS = {
		containerThanks: '[data-zg-role="thanks_container"]'
	};

	var SendEmail = function ( element, options ) {
		this.$element = $( element );

		this.defaultOptions = _.clone( DEFAULTS );
		this.options        = _.extend( this.defaultOptions, options || {} );

		this.$containerThanks= $( element).find(this.options.containerThanks);

		if ( this.$element.is('form') ) {
			this.$zgSendEmailForm = this.$element;
		} else {
			this.$zgSendEmailForm = this.$element.find('form');
		}

		this.$templateDataContainer = this.$element.find( '[data-zg-role="template_data"]' );
	};



    /**
     * Function for serializing the form inputs.
     * The filtering process is for removing the fields added for the Google captcha
     * @private
     */
    SendEmail.prototype.__getFormData = function () {
        var data = this.$zgSendEmailForm.serializeArray();

        data = _.filter( data, function ( item ) {
            return (item.name !== 'g-recaptcha-response') && (item.name !== 'hiddenRecaptcha');
        } );

        return data;
    };


	/**
	 * Function for send email with ajax call
	 * @private
	 */
	SendEmail.prototype.__send = function () {

		var formData;

		formData = new FormData(this.$zgSendEmailForm.get(0));

		/*ADD DELETE CAPTCHA*/
		formData.delete('g-recaptcha-response');
		formData.delete('hiddenRecaptcha');
		/*END ADD DELETE CAPTCHA*/

		formData.append('action','sendFormEmail');

		$.ajax( {
			type:     'post',
			url:      root.makeUrl( { module: 'eshop', manager: 'eshop', action: 'sendFormEmail' } ),
			dataType: 'json',
			data:     formData,
			contentType: false,
			processData: false,

			beforeSend: function () {
				this.__onBeforeSend();
			}.bind( this ),

			success: function ( response ) {
				this.__onSuccess( response );
			}.bind( this ),

			error: function ( response ) {
				this.__onError( response );
			}.bind( this ),

			complete: function () {
				this.__onComplete();
			}.bind( this )
		} );
	};

	/**
	 * Before send add class loading for hide de form
	 * @private
	 */
	SendEmail.prototype.__onBeforeSend = function () {
		this.$element.addClass( 'loading' );
	};

	/**
	 * On complete remove class loading for show the form
	 * @private
	 */
	SendEmail.prototype.__onComplete = function () {
		if(  this.$containerThanks.length ){
			//show thanks
			//this.$containerThanks.collapse('show');
			
		}
		this.$element.removeClass( 'loading' );
	};


	/**
	 * If ajax make an error show to the user the error
	 * @private
	 */

	/**
	 * @method __onError
	 * @fires SendEmail#zg-error When the ajax call make an error
	 */

	SendEmail.prototype.__onError = function () {
		$( document ).trigger( 'zg-error', [{
			eventType: 'sendFormEmail',
			message:   ('Request Failed')
		}] );
	};

	/**
	 * On success it is checked the response of the Ajax call. On success, if exists the proper data attributes in order to send
     * an email to the user, then it will be replaced the "to_email" input as much as the "subject" one and it will fired
     * the "sendEmailToUser" function.
     * Finally, it will be cleared all inputs.
	 * @private
	 */

	/**
	 * @method __onSuccess
	 * @fires SendEmail#zg-notification When the ajax call is success
	 */

	/**
	 * @method __onSuccess
	 * @fires SendEmail#zg-error When the ajax call success, but the status is not success
	 */

	SendEmail.prototype.__onSuccess = function ( response ) {
		var emailUser = this.$element.find( '[data-zg-role="email_user"]' ).val();
    var subjectEmail = this.$templateDataContainer.data( 'subject-email-user' );
		var $toEmailInput = this.$element.find( 'input[name="to_email"]' );
    var $subjectEmailInput = this.$element.find( 'input[name="subject"]' );

		if ( DEBUG ) {
			console.log( 'SendEmail - SUCCESS', response );
		}

		if ( response && response.status === 'success' ) {
			/*
			if( emailUser ){
				emailUser = window.btoa( emailUser );
				$toEmailInput.val( emailUser );
				$subjectEmailInput.val( subjectEmail );
				this.__sendEmailToUser( response );
			*/	
		
			if(  this.$element.find('#thanksContainer').length ){
					//show thanks
					this.$element.find('#thanksContainer').removeClass('hidden');
					//hide form
					this.$element.find('form').addClass('hidden');
					//hide description
					this.$element.find('#descriptionFormContainer').addClass('hidden');

			} else {
					$( document ).trigger( 'zg-notification', [{
							eventType: 'sendFormEmail',
							message: response.message
					}] );
			}
			this.$zgSendEmailForm.get(0).reset();

		} else {
			$( document ).trigger( 'zg-error', [{
				eventType: 'sendFormEmail',
				message:   response.message
			}] );
		}
	};


    /**
     * Function for parsing the expected object that will use a custom template email
     * @private
     */
	SendEmail.prototype.__sendEmailToUser = function (){
		var templateDataValues = this.$templateDataContainer.data() || {};

		if ( this.$zgSendEmailForm.data( 'zgValidator' ) ) {
			if ( !this.$zgSendEmailForm.data( 'zgValidator' ).validate() ) {
				return false;
			}
		}

		var data = this.__getFormData();

		data.push({
			name: "template_name",
			value: this.options.template_name
		})

		_.each( templateDataValues, function ( item, key ) {
			data.push({
				name: 'template_data[' + key + ']',
				value: item
			})
		} );


		zgPost( 'sendFormEmail', data, {success: []}, {
			error: _.bind( function ( response ) {
				this.__onError( response );
			}, this )
		} );
	}



	function Plugin ( option ) {
		return this.each( function () {
			var $this = $( this );
			var data = $this.data( 'zg.sendEmail' );
			var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.sendEmail', (data = new SendEmail( this, options )) );
			}

			// make sure we always send the ajax request on form submit
			data.__send();
		} );
	}

	$.fn.sendEmail = Plugin;
	$.fn.sendEmail.Constructor = SendEmail;


	/**
	 * @method document
	 * @listen submit On submit of SELECTOR start the plugin
	 * */

	$( function () {
		$( document ).on( 'submit', SELECTOR, function ( e ) {
			e.preventDefault();
			Plugin.call($(this));
		} );
	});

}).call( this, jQuery, _ );