/**
 * Exit Intent Popup:
 *
 * Show a modal when the user moves his mouse outside the document
 * modal's html is loaded asynchronously
 * create a cookie to avoid being annoying
 *
 * required:
 *   - js functions window.readCookie() and windows.createCookie()  ( usually in /base/zg-utils.js )
 *   - /eshop/modal_exit_intent_popup.html   (bootstrap modal)
 *   - set ZG_CONFIG.showExitIntentPopup as 'true' in /global/_common_js_includes.html to show the modal
 *
 * installation:
 *   - add a fake view in cmsList --> {elseif $result->type == 'exit_intent_popup' }	{include file="eshop/modal_exit_intent_popup.html"}	{/if}
 *   - set ZG_CONFIG.showExitIntentPopup as 'true' in /global/_common_js_includes.html to show the modal
 *
 * @author: Giuseppe Ciotola  <gciotola[at]zerogrey[dot]com>
 * @author: David Pocina      <dpocina[at]kooomo[dot]com>
 */

/**
 * @event document#mouseout.exitIntentPopup This should be enough to track if mouse cursor goes outside the viewport
 * @type {null}
 */

(function ( $, _ ) { /* global _, ZG_CONFIG, zgCreateCookie, zgReadCookie */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	/**
	 * @param {string} [exitIntentModal] ID of the boostrap modal
	 * @param {string} [exitIntentCookie] Name of cookie to read/set for show the modal
	 * @param {number} [exitIntentCookieExpiration] When the cookie is expired. 0.0007 is 1 minute, 0.0033 is 4~5 minutes.  1 is one day (24h)
	 */

	var DEFAULTS = {
		exitIntentModal:            '#exitpopup',
		exitIntentCookie:           'ZG_exitpopupclosed',
		exitIntentCookieExpiration: 1
	};

	/**
	 *
	 * @constructor
	 */
	var ExitIntentPopup = function ( options ) {
		this.options     = _.extend( DEFAULTS, options || {} );
		this.modalLoaded = false;
		this.modalShown  = zgReadCookie( this.options.exitIntentCookie );

		this.__loadAjaxModal();
		this.__setEventHandlers();
	};

	/**
	 *
	 */
	ExitIntentPopup.prototype.showModal = function () {
		if ( this.modalLoaded && !this.modalShown && $( '.modal.in' ).length === 0 ) {
			// the modal is loaded, is not shown and there is no other modal open at the moment
			$( this.options.exitIntentModal ).modal( 'show' );
			this.modalShown = true;  // to avoid to show the modal again
		}
	};


	/**
	 * Put here code to be executed after modal has been loaded
	 *
	 * @private
	 */
	ExitIntentPopup.prototype.__bindModalEvent = function () {
		var that = this;

		$( this.options.exitIntentModal ).on( 'hide.bs.modal', function () {
			zgCreateCookie( that.options.exitIntentCookie, 'true', that.options.exitIntentCookieExpiration );
		} );
	};

	/**
	 *
	 * @private
	 */
	ExitIntentPopup.prototype.__loadAjaxModal = function () {
		var that, url;

		if ( !this.modalLoaded ) {
			that = this;

			url = window.makeUrl( {
				module: 'eshop', manager: 'cms', action: 'list', type: 'Exitpopup', master: 'master_blank'
			} );

			$.ajax( {
				url:      url,
				cache:    true,
				dataType: "html",
				headers:  {
					'X-Requested-With': 'empty'
				},
				success:  function ( responseText ) {
					$( 'body' ).append( responseText );
					that.modalLoaded = true;
					that.__bindModalEvent();
				}
			} );
		}
	};

	/**
	 * @method __setEventHandlers
	 * @listen document#mouseout.exitIntentPopup If mouse cursor goes outside the viewport show modal.
	 *
	 * @private
	 */
	ExitIntentPopup.prototype.__setEventHandlers = function () {
		var that = this;

		$( document ).on( 'mouseout.exitIntentPopup', function ( e ) {
			e        = e ? e : window.event;
			var from = e.relatedTarget || e.toElement;
			if ( !from || from.nodeName === "HTML" ) {
				that.showModal();
			}
		} );
	};


	// INITIALIZE
	if ( ZG_CONFIG.showExitIntentPopup === true ) {
		$( function () {
			root.exitIntentPopup = new ExitIntentPopup( ZG_CONFIG );
		} );
	}

}).call( this, jQuery, _ );
